import "react-image-lightbox/style.css";

import {
  Box,
  Button,
  Chip,
  Container,
  Grid,
  Hidden,
  Link,
  Paper,
  Typography,
  Zoom,
  makeStyles,
} from "@material-ui/core";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import EmailIcon from "@material-ui/icons/Email";
import Lightbox from "react-image-lightbox";
import PhoneIcon from "@material-ui/icons/Phone";
import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import grey from "@material-ui/core/colors/grey";
import * as nl2br from "react-nl2br";
import clsx from "clsx";

import Facts from "../offers/Facts";
import ExposeSubscription from "../expose/ExposeSubscription";
import KeyValueList from "../offers/KeyValueList";
import Separator from "../layout/Separator";
import * as constants from "../../constants";
import teamImage from "../../images/teamfoto23.jpg";
import { useSelector } from "react-redux";
import convert from "htmr";
import Img360 from "../offers/Img360";
import Video from "../video/Video";
import getAreaValues from "../offers/utils/getAreaValues";
import { hiddenOffers } from "../../utils/hiddenOffers";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(8),
  },
  title: {
    marginTop: theme.spacing(6),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(0, 20),
    },
  },
  lineThroughTitle: {
    textDecoration: "line-through",
  },
  subtitle: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      marginBottom: theme.spacing(6),
    },
  },
  slide: {
    height: 300,
    display: "block",
    cursor: "pointer",

    [theme.breakpoints.up("sm")]: {
      height: 400,
    },
  },
  slideWrapper: {
    margin: "0 2px",
    position: "relative",
  },
  lightbox: {
    zIndex: 9999,
  },
  bodyText: {
    "&:first-line": {
      [theme.breakpoints.up("sm")]: {
        fontWeight: "bold",
      },
    },
  },
  location: {
    fontSize: 20,
    marginTop: theme.spacing(0.5),
  },
  locationExpose: {
    fontSize: 20,
    fontWeight: 500,
    [theme.breakpoints.up("sm")]: {
      fontSize: 24,
    },
  },
  exposeHint: {
    fontSize: 20,
    fontWeight: 500,
    paddingTop: theme.spacing(10),
    marginBottom: theme.spacing(-6),
    [theme.breakpoints.up("sm")]: {
      fontSize: 24,
    },
  },
  sliderWrapper: {
    marginTop: 0,
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(3),
    },
  },
  slider: {
    background: grey[300],
    marginTop: theme.spacing(2),
  },
  sliderExpose: {
    background: grey[900],
  },
  paperData: {
    padding: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(6),
    },
  },
  objektbeschreibung: {
    padding: theme.spacing(2),
    margin: theme.spacing(0),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(6, 2),
      margin: theme.spacing(0),
    },
    [theme.breakpoints.up("lg")]: {
      padding: theme.spacing(6),
      margin: theme.spacing(0, 20),
    },
  },
  youtube: {
    padding: theme.spacing(2),
    margin: theme.spacing(0),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(6, 2, 0, 2),
      margin: theme.spacing(0),
    },
    [theme.breakpoints.up("lg")]: {
      padding: theme.spacing(6, 6, 0, 6),
      margin: theme.spacing(0, 20),
    },
  },
  arrowNext: {
    width: 50,
    height: 50,
    right: -50,
    color: grey[500],
  },
  arrowPrev: {
    width: 50,
    height: 50,
    left: -50,
    color: grey[500],
  },
  exposeChipWrapper: {
    padding: theme.spacing(8, 0, 0, 0),
    display: "flex",
    justifyContent: "center",
  },
  exposeChip: {
    background: grey[900],
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: 18,
    padding: theme.spacing(2, 1),
  },
  ansprechpartnerPaper: {
    padding: theme.spacing(0, 0, 4, 0),
  },
  ansprechpartnerKontakt: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: theme.spacing(1, 0),
  },
  ansprechpartnerIcon: {
    color: grey[400],
    marginRight: theme.spacing(1),
  },
  bigAvatar: {
    width: 90,
    height: 90,
  },
  hint: {
    color: theme.palette.error.main,
    marginTop: theme.spacing(5),
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
  },
  overlayChip: {
    position: "absolute",
    bottom: theme.spacing(5),
    left: theme.spacing(5),
  },
}));

const eckdaten = [
  { label: "Objektart", key: "objektart" },
  { label: "Baujahr", key: "baujahr" },
  { label: "Lageart", key: "lageart" },
  { label: "Kaufpreis", key: "kaufpreis" },
  { label: "Kaltmiete", key: "kaltmiete" },
  { label: "Nebenkosten", key: "nebenkosten" },
  { label: "Warmmiete", key: "warmmiete" },
  { label: "Kaution", key: "kaution" },
  { label: "Etage", key: "etage" },
  { label: "Anzahl Etagen", key: "etagenzahl" },
  { label: "Aufzug", key: "aufzug" },
  {
    label: "Grundstücksfläche",
    key: "grundstueck",
    postfix: (
      <>
        m<sup>2</sup>
      </>
    ),
  },
  {
    label: "Wohnfläche",
    key: "wohnflaeche",
    postfix: (
      <>
        m<sup>2</sup>
      </>
    ),
  },
  {
    label: "Nutzfläche",
    key: "nutzflaeche",
    postfix: (
      <>
        m<sup>2</sup>
      </>
    ),
  },
  { label: "Anzahl Zimmer", key: "zimmer" },
  { label: "Anzahl Balkone", key: "balkone" },
  { label: "Terrasse", key: "terrassen" },
  { label: "Balkon/Terrasse", key: "balkon_terrasse" },
  { label: "Küche vorhanden", key: "kueche" },
  { label: "Einbauküche", key: "einbaukueche" },
  { label: "Stellplatztyp", key: "stellplatztyp" },
  { label: "Anzahl Stellplätze", key: "stellplaetze" },
  { label: "Garagenanzahl", key: "garagenanzahl" },
  { label: "Keller", key: "keller" },
  { label: "Badezimmer", key: "bad" },
  { label: "Ausstattung", key: "ausstattung" },
  { label: "Bodenbelag", key: "bodenbelag" },
  { label: "Provision", key: "provision" },
  { label: "Provisionshinweis", key: "provision_hinweis" },
];

const energie = [
  { label: "Energieausweis liegt vor", key: "energieausweis_liegt_vor" },
  { label: "Energieausweis", key: "energieausweis" },
  { label: "Gültig bis", key: "gueltig_bis" },
  { label: "Ausgestellt am", key: "ausgestellt_am" },
  { label: "Wesentlicher Energieträger", key: "energietraeger" },
  { label: "Art der Heizung", key: "heizung" },
  { label: "Baujahr Wärmeerzeugung", key: "baujahr_waerme" },
  { label: "Energieeffizienz", key: "energieeffizienz" },
  {
    label: "Endenergieverbrauch",
    key: "endenergieverbrauch",
    postfix: (
      <>
        kWh/(m<sup>2</sup>*a)
      </>
    ),
  },
  {
    label: "Endenergiebedarf",
    key: "endenergiebedarf",
    postfix: (
      <>
        kWh/(m<sup>2</sup>*a)
      </>
    ),
  },
];

function CustomNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <ChevronRightIcon
      className={className}
      style={{
        ...style,
        display: "block",
        width: 50,
        height: 50,
        color: grey[700],
        right: -50,
      }}
      onClick={onClick}
    />
  );
}

function CustomPrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <ChevronLeftIcon
      className={className}
      style={{
        ...style,
        display: "block",
        width: 50,
        height: 50,
        color: grey[700],
        left: -50,
      }}
      onClick={onClick}
    />
  );
}

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  centerMode: true,
  variableWidth: true,
  nextArrow: <CustomNextArrow />,
  prevArrow: <CustomPrevArrow />,
};

const settingsMobile = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerMode: false,
  variableWidth: true,
  arrows: false,
};

const OfferDetail = ({ match, archived = false }) => {
  const classes = useStyles();
  const [entry, setEntry] = useState(null);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [showSlider, setShowSlider] = useState(false);
  const [invalidToken, setInvalidToken] = useState(false);
  const [missingAcceptance, setMissingAcceptance] = useState(false);
  const websiteSettings = useSelector((state) => state.settings);

  useEffect(() => {
    const id = match.params.id;
    const token = match.params.token;

    if (hiddenOffers.includes(id)) return;

    if (id) {
      fetch(
        constants.API_URL +
          (archived ? "archived-objects" : "objects") +
          "/" +
          id
      )
        .then((result) => {
          return result.json();
        })
        .then((data) => {
          if (data && data.id) {
            setEntry({
              ...data,
            });
            setShowSlider(true);
          }
        });
    } else if (token) {
      fetch(constants.API_URL + "objects/expose/" + token)
        .then((result) => {
          return result.json();
        })
        .then((data) => {
          if (data.result === "Invalid Token") {
            setInvalidToken(true);
          } else if (data.result === "missing_acceptance") {
            setMissingAcceptance(true);
          } else {
            setEntry({
              ...data,
            });
            setShowSlider(true);
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      entry &&
      (entry.id === "2009" || entry.id === "c598ce4b1f6d66f15d681ff965c476")
    ) {
      entry.preise.kaufpreis = parseFloat(224500);
    }
  }, [entry]);

  const showExposeDesc = websiteSettings.show_expose_description && (
    <>{convert(websiteSettings.show_expose_description)}</>
  );

  const handleOpenLightbox = (event, index) => {
    const id = event.target.id;
    setIsLightboxOpen(true);
    setPhotoIndex(parseInt(id));
  };

  const id = match.params.id;
  const token = match.params.token;

  if (missingAcceptance) {
    return <Redirect push to={"/expose/agb/" + token} />;
  }

  if (invalidToken) {
    return (
      <>
        <br />
        <br />
        <Typography variant="h5" align="center">
          Ungültiger Link
        </Typography>
        <Typography variant="body1" align="center">
          Bitte überprüfen Sie, ob Sie den Link vollständig kopiert und
          eingefügt haben
        </Typography>
      </>
    );
  }

  if (!entry) {
    return null;
  }

  const isDiscreteProperty = (id) => id === "23038---uqbt44" || id === "24038---uqbt44";
  let anh = isDiscreteProperty(match.params.id) || isDiscreteProperty(entry?.id)
      ? [entry.anhaenge[0]]
      : entry.anhaenge.slice(1);

  // Bei ausführlichem Exposé bei dem 9. Bild starten, wenn vorhanden
  if (anh.length > 8) {
    let exposeAnh = [...anh.filter((f, i) => i > 8)];
    exposeAnh = [...exposeAnh, ...anh.filter((f, i) => i <= 8)];
    anh = exposeAnh;
  }

  let images = anh
    .filter((f) => (f.daten.titel || "").indexOf("360") !== 0)
    .map((item) => constants.API_URL + item.daten.pfad);

  const images360 = anh
    .filter((f) => (f.daten.titel || "").indexOf("360") === 0)
    .map((item) => ({
      pfad: constants.API_URL + item.daten.pfad,
      pfad_preview: constants.API_URL + item.daten.pfad_preview,
      titel: (item.daten.titel || "").replace("360", "").trim(),
    }));

  const kaltmiete = entry.preise.hasOwnProperty("kaltmiete")
    ? parseFloat(entry.preise.kaltmiete)
    : null;

  const slickEntries = anh
    .filter((f) => (f.daten.titel || "").indexOf("360") !== 0)
    .map((item, index) => (
      <div key={index} className={classes.slideWrapper}>
        <img
          id={index}
          src={constants.API_URL + item.daten.pfad_preview}
          alt=""
          className={classes.slide}
          onClick={handleOpenLightbox}
        />
        <Chip className={classes.overlayChip} label={item.daten.titel} />
      </div>
    ));

  const getYtLinkWithoutPrefix = () => {
    const videoLink = entry.youtube_video_expose || entry.youtube_video;
  
    if (videoLink) {
      return videoLink.includes("http://") ? videoLink.split("http://")[1] : videoLink;
    }
  
    return videoLink;
  };

  const renderHint = () => {
    const status = entry?.freitexte?.dreizeiler?.toLowerCase();

    if (status === "verkauft" || status === "vermietet") {
      return (
        <Typography variant="h5" className={classes.hint}>
          Diese Immobilie ist {status}, bitte nicht anfragen.
        </Typography>
      );
    }

    return null;
  };

  const excludedIds = [
    "22074---uqbt44",
    "22074#uqbt44",
    "22085---uqbt44",
    "22085#uqbt44",
    "23029---uqbt44",
    "23029#uqbt44",
    "23025---uqbt44",
    "23025#uqbt44",
    "23038---uqbt44",
    "23038#uqbt44",
    "24027---uqbt44",
    "24027#uqbt44",
    "24038---uqbt44",
    "24038#uqbt44"
  ];

  return (
    <>
      {slickEntries && Boolean(slickEntries.length) && (
        <Zoom
          in={showSlider}
          style={{ transitionDelay: "500ms" }}
          className={clsx(classes.sliderWrapper, "angebot-detail")}
        >
          <div
            className={clsx(
              Boolean(entry.strasse) && classes.sliderExpose,
              classes.slider
            )}
          >
            <Hidden mdDown>
              <Container maxWidth="lg">
                <Slider {...settings}>{slickEntries}</Slider>
              </Container>
            </Hidden>
            <Hidden lgUp>
              <Slider {...settingsMobile}>{slickEntries}</Slider>
            </Hidden>
          </div>
        </Zoom>
      )}
      <Container maxWidth="lg" className={classes.root}>
        {isLightboxOpen && (
          <Lightbox
            reactModalStyle={{
              overlay: { zIndex: 9999 },
            }}
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => setIsLightboxOpen(false)}
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + images.length - 1) % images.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % images.length)
            }
          />
        )}

        {renderHint()}
        {Boolean(entry.strasse) && (
          <>
            <Typography
              variant="body2"
              align="center"
              color="secondary"
              component="p"
              className={classes.exposeHint}
            >
              Hier finden Sie Ihr exklusives Exposé mit weiteren Informationen
              und der Objektadresse
            </Typography>

            {Boolean(entry.video_title) && entry.video_title !== "-" && (
              <Box pt={10} pb={2} mb={-6} align="center">
                <Typography variant="h6">Weitere Informationen</Typography>
                <p>
                  {Boolean(entry.video_link) && entry.video_link !== "-" && (
                    <Button
                      variant="contained"
                      color="secondary"
                      size="large"
                      component={Link}
                      href={entry.video_link}
                      target="_blank"
                    >
                      {entry.video_title}
                    </Button>
                  )}
                </p>
              </Box>
            )}
          </>
        )}
        <Typography
          variant="h2"
          align="center"
          className={clsx(
            classes.title,
            ["verkauft", "vermietet"]?.includes(
              entry?.freitexte?.dreizeiler?.toLowerCase()
            )
              ? classes.lineThroughTitle
              : null
          )}
        >
          {entry.freitexte.objekttitel}
        </Typography>
        <Typography
          variant="body2"
          align="center"
          color={Boolean(entry.strasse) ? "secondary" : "textSecondary"}
          component="p"
          className={clsx(
            Boolean(entry.strasse) && classes.locationExpose,
            classes.location
          )}
        >
          {Boolean(entry.strasse) && entry.strasse}
          {Boolean(entry.hausnummer) && " " + entry.hausnummer}
          {Boolean(entry.strasse) && ", "}
          {entry.plz} {entry.ort}
        </Typography>
        <Facts flaechen={getAreaValues(entry)} preise={entry.preise} />

        <Img360 images={images360} />

        {Boolean(entry.youtube_video_expose || (entry.youtube_video && !isDiscreteProperty(entry.id))) && (
          <div className={classes.youtube}>
            <Video yt={getYtLinkWithoutPrefix()} title="Videoführung" />
          </div>
        )}

        {Boolean(entry.strasse) && (
          <>
            <Separator />
            <Container maxWidth="xs">
              <Paper className={classes.ansprechpartnerPaper}>
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  direction="column"
                  spacing={3}
                >
                  <Grid item xs={12} style={{ paddingTop: 0 }}>
                    <img
                      style={{
                        width: "100%",
                        height: "auto",
                        borderTopLeftRadius: "4px",
                        borderTopRightRadius: "4px",
                      }}
                      src={teamImage}
                      alt="Das Team von Marquart Immobilien in Bad Kissingen"
                    />
                  </Grid>
                  <Grid item xs={12} align="center">
                    <Typography variant="h4" style={{ fontSize: "22px" }}>
                      Unser Team
                    </Typography>
                    <Typography variant="overline">
                      steht Ihnen für Fragen gerne zur Verfügung
                    </Typography>
                  </Grid>
                  <Grid item xs={12} align="center">
                    <Typography
                      variant="body1"
                      className={classes.ansprechpartnerKontakt}
                    >
                      <PhoneIcon className={classes.ansprechpartnerIcon} /> 0971
                      123 82 98 0
                    </Typography>
                    <Typography
                      variant="body1"
                      className={classes.ansprechpartnerKontakt}
                    >
                      <EmailIcon className={classes.ansprechpartnerIcon} />
                      <Link
                        color="textPrimary"
                        href="mailto:kundenanfrage@marquart-immobilien.de"
                      >
                        kundenanfrage@marquart-immobilien.de
                      </Link>
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Container>
          </>
        )}

        {!Boolean(entry.strasse) && !archived && (
          <>
            <Separator />
            <Typography variant="h2" align="center">
              {websiteSettings.show_expose_title}
            </Typography>
            <Typography
              variant="subtitle1"
              align="center"
              component="div"
              className={classes.subtitle}
            >
              {showExposeDesc}
            </Typography>
            <ExposeSubscription
              object_id={id}
              object_price={
                entry.preise.kaufpreis
                  ? entry.preise.kaufpreis
                  : entry.preise.kaltmiete
              }
              object_title={entry.freitexte.objekttitel}
              object_provision={entry.eckdaten.provision}
            />
          </>
        )}
        <Separator />
        <Paper className={classes.paperData}>
          <Typography variant="h3" align="center" className={classes.subtitle}>
            Übersicht
          </Typography>
          <Grid container spacing={6}>
            <Grid item xs={12} md={6}>
              <KeyValueList
                title="Eckdaten"
                items={eckdaten}
                data={entry.eckdaten}
              />
            </Grid>
            {!excludedIds.includes(match.params.id) &&
              !excludedIds.includes(entry?.id) && (
                <Grid item xs={12} md={6}>
                  <KeyValueList
                    title="Energie/Verbrauch"
                    items={energie}
                    data={entry.energie}
                  />
                </Grid>
              )}
          </Grid>
        </Paper>

        <Separator />
        <Paper className={classes.objektbeschreibung}>
          <Typography variant="h2" align="center" className={classes.subtitle}>
            Objektbeschreibung
          </Typography>
          <Typography variant="body1" className={classes.bodyText}>
            {nl2br(entry.freitexte.objektbeschreibung)}
          </Typography>
        </Paper>
      </Container>
      <Helmet>
        <title>
          {Boolean(kaltmiete) ? "Zu vermieten:" : "Zu verkaufen:"}{" "}
          {entry.freitexte.objekttitel} in {entry.plz} {entry.ort}
        </title>
        <meta
          name="description"
          content="Ihr Immobilienmakler in der Region Bad Kissingen und Würzburg. Kundenorientierung, Professionalität und Expertise stehen im Fokus unserer täglichen Arbeit. Gerne beraten wir Sie persönlich. Ihr Team von MARQUART IMMOBILIEN"
        />
      </Helmet>
    </>
  );
};

export default OfferDetail;
