import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Fact from "./Fact";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  rootSmall: {
    justifyContent: "flex-start",
  },
}));

const Facts = ({ size, flaechen, preise }) => {
  const classes = useStyles();

  if (!flaechen || !preise) {
    return null;
  }

  const zimmer = flaechen.anzahl_zimmer;
  const wohnflaeche = flaechen.wohnflaeche;
  const gesamtflaeche = flaechen.gesamtflaeche;
  const grundstueck = flaechen.grundstuecksflaeche || null;
  const preis = preise.kaufpreis ? preise.kaufpreis : preise.kaltmiete;
  const preisLabel = preise.kaufpreis ? "Kaufpreis" : "Kaltmiete";

  return (
    <div className={clsx(classes.root, size === "small" && classes.rootSmall)}>
      {zimmer && (
        <Fact value={zimmer} label="Zimmer" type="zimmer" size={size} />
      )}
      {wohnflaeche && (
        <Fact
          value={wohnflaeche}
          label="Wohnfläche ca."
          type="flaeche"
          size={size}
          unit="qm"
        />
      )}
      {gesamtflaeche && (
        <Fact
          value={gesamtflaeche}
          label="Gesamtfläche ca."
          type="flaeche"
          size={size}
          unit="qm"
        />
      )}
      {grundstueck && (
        <Fact
          value={grundstueck}
          label="Grundstück ca."
          type="grundstueck"
          size={size}
          unit="qm"
        />
      )}
      {size !== "small" && (
        <Fact type="preis" value={preis} label={preisLabel} size={size} />
      )}
    </div>
  );
};

export default Facts;
